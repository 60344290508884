import dayjs from 'dayjs';
import { ReceiptEventPackageItemProps, ReceiptEventPackageProps } from '../../../datastore/ssccs';
import { ManifestProps } from '../../../datastore/tripSheets';
import { ManifestPackageStatus } from '../../../common/status';
import { variousAsn } from '../../../common/constants';

export function mapToReceiptedItems(manifest: ManifestProps): Partial<ReceiptEventPackageProps>[] {
  const asnMap = new Map<string, Partial<ReceiptEventPackageProps>>();

  manifest?.ssccs?.forEach((sscc) => {
    sscc.packages?.forEach((pkg) => {
      if (
        pkg.receivedStatus !== ManifestPackageStatus.qm_pending &&
        pkg.receivedStatus !== ManifestPackageStatus.qm &&
        pkg.asn !== variousAsn &&
        (pkg.inventoryLines ?? []).length > 0
      ) {
        const items: ReceiptEventPackageItemProps[] = pkg.inventoryLines!.map((line) => ({
          orderLineId: line.orderLineId,
          partId: line.partId,
          sourcePartId: line.sourcePartId ?? '',
          quantityReceived: line.quantityReceived ?? 0,
          purchaseType: line.purchaseType,
          receipt: dayjs(),
          receivingWarehouseId: line.receivingWarehouseId ?? null,
          receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
          inTransitWarehouseId: line.inTransitWarehouseId ?? null,
          inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
        }));

        if (!asnMap.has(pkg.asn)) {
          asnMap.set(pkg.asn, {
            id: pkg.id,
            asn: pkg.asn,
            quantity: pkg.quantity ?? 0,
            items: items
          });
        } else {
          const existingEntry = asnMap.get(pkg.asn)!;
          existingEntry.items = [...(existingEntry.items ?? []), ...items];
        }
      }
    });
  });

  // Deduplicate items within each ASN
  for (const [asn, entry] of asnMap) {
    if (entry.items) {
      const seenItems = new Set<string>();
      entry.items = entry.items.filter((item) => {
        const key = `${item.orderLineId}-${item.partId}-${item.sourcePartId}-${item.quantityReceived}`;
        if (seenItems.has(key)) {
          return false; // This is a duplicate, filter it out
        }
        seenItems.add(key);
        return true;
      });
    }
  }

  return Array.from(asnMap.values()).filter((entry) => (entry.items?.length ?? 0) > 0);
}
