/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CreateQMProps, UpdateAsnProps } from '../../../api/postQM';
import { variousAsn } from '../../../common/constants';
import {
  convertReceiptEventToCoupaReceiptEvent,
  createQMCoupa,
  createReceiptCoupa,
  createSiteReceiptCoupa,
  updateManifestAsnsCoupa
} from '../../../common/helperFunctions/coupa';
import { updateAsnStatus } from '../../../common/helperFunctions/sscc';
import { ManifestLegStatus, ManifestPackageStatus } from '../../../common/status';
import { ftlPrefix, galaxyNoteThreeDimensions, transportBookingFormPrefix } from '../../../common/strings';
import {
  DarkButton,
  DarkUpperSection,
  LightInfoButton,
  LoadingSpinner,
  PageSection,
  SearchField,
  SearchNotFoundCard,
  StyledButton,
  StyledTableHead,
  StyledTableRow
} from '../../../components';
import DisabledPrintAlert from '../../../components/alerts/DisabledPrintAlert';
import { ConfirmationDialog } from '../../../components/modals/ConfirmDialog';
import DirectReceiptModel from '../../../components/modals/DirectReceiptForm';
import LoadingModal from '../../../components/modals/LoadingModal';
import RaiseQMForm, { RaiseQMModel } from '../../../components/modals/RaiseQMForm';
import { ReceiptEventPackageProps } from '../../../datastore/ssccs';
import {
  ManifestPackageProps,
  ManifestProps,
  ManifestSSCCProps,
  ManifestStatus,
  TripSheetProps
} from '../../../datastore/tripSheets';
import { UserDetailsProps } from '../../../datastore/userDetails';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../../hooks';
import { useDirtyContext } from '../../../hooks/useDirtyProvider';
import { useDisabledPrinterContext } from '../../../hooks/useIsDisabledPrinter';
import useObserveTripsheet from '../../../hooks/useObserveTripsheet';
import { mapToReceiptedItems } from './detail.mapping';

interface QMDictionary {
  key: string;
  value: CreateQMProps;
}

interface DirectDictionary {
  key: string;
  value: ReceiptEventPackageProps;
}

export default function ReceiveManifestDetails() {
  const { id } = useParams();
  const theme = useTheme();
  const alert = useAlert();
  const store = useDatastore();
  const user = useCurrentUser();
  const { isOnline } = useIsOnline();
  const { setIsDirty } = useDirtyContext();
  const { isDisabled: isAnyPrinterDisabled } = useDisabledPrinterContext();
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [tripData, setTripData] = useState<TripSheetProps>();
  const [manifest, setManifest] = useState<ManifestProps>();
  const [search, setSearch] = useState('');
  const [openRaiseQM, setOpenRaiseQM] = useState(false);
  const [openDirectModel, setopenDirectModel] = useState(false);
  const [selectedASN, setSelectedASN] = useState<string>('');
  const [qmDictionary, setQMDictionary] = useState<QMDictionary[]>([]);
  const [directDictionary, setDirectDictionary] = useState<DirectDictionary[]>([]);
  const [filteredSsccs, setFilteredSsccs] = useState<ManifestSSCCProps[]>();
  const [originalManifest, setOriginalManifest] = useState<ManifestProps>();
  const [openDirtyDialog, setOpenDirtyDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsProps | null>(null);
  const { tripId, legStatus, legDestination } = location.state;
  const tripsheet = useObserveTripsheet(tripId || '');

  useEffect(() => {
    async function getUser() {
      const userDetails = await store.userDetails.get(user.cognitoUser.username);
      if (userDetails) {
        setUserDetails(userDetails);
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        if (!tripsheet) return;
        setTripData(tripsheet);
        let foundManifest: ManifestProps | undefined;

        for (const leg of tripsheet.legs) {
          foundManifest = leg.manifests.find((m) => m.id === id);
          if (!foundManifest) {
            continue;
          }
          mergeExistingLinesWithSubscription(foundManifest);
          setManifest(foundManifest);

          if (!originalManifest) {
            setOriginalManifest(JSON.parse(JSON.stringify(foundManifest)));
          }
        }
      } catch (error) {
        console.error(error);
        alert('Failed to get TripSheet data', 'error');
      } finally {
        setIsLoading(false);
      }
    }

    if (!tripId || !id) return;
    getData();
  }, [tripsheet]);

  useEffect(() => {
    const filteredResults = manifest?.ssccs.filter((sscc) => sscc.packages.some((pkg) => pkg.asn.includes(search)));
    setFilteredSsccs(filteredResults);
  }, [search, manifest]);

  function updateSsccLine() {
    if (!manifest || manifest.status === ManifestStatus.completed) {
      return;
    }

    const updatedSsccs = manifest.ssccs.map((sscc) => {
      const nonVariousPackages = sscc.packages.filter((pkg) => pkg.asn !== variousAsn);

      const isComplete = nonVariousPackages.every(
        (pkg) =>
          pkg.receivedStatus === ManifestPackageStatus.qm ||
          pkg.receivedStatus === ManifestPackageStatus.received ||
          pkg.receivedStatus === ManifestPackageStatus.direct ||
          pkg.receivedStatus === ManifestPackageStatus.qm_pending ||
          pkg.receivedStatus === ManifestPackageStatus.direct_pending
      );

      const isPartial = nonVariousPackages.some(
        (pkg) =>
          pkg.receivedStatus === ManifestPackageStatus.partial ||
          pkg.receivedStatus === ManifestPackageStatus.received ||
          pkg.receivedStatus === ManifestPackageStatus.qm_pending ||
          pkg.receivedStatus === ManifestPackageStatus.direct_pending
      );

      const updatedPackages = sscc.packages.map((pkg) => {
        if (pkg.asn === variousAsn) {
          return {
            ...pkg,
            receivedStatus: isComplete
              ? ManifestPackageStatus.received
              : isPartial
              ? ManifestPackageStatus.partial
              : ManifestPackageStatus.notReceived
          };
        }
        return pkg;
      });

      return { ...sscc, packages: updatedPackages };
    });

    setManifest({ ...manifest, ssccs: updatedSsccs });
  }

  const canSubmit = useMemo(() => {
    if (!manifest) {
      return false;
    }

    const flatPackages = manifest.ssccs.flatMap((sscc) => sscc.packages);
    const packagesWithoutVarious = flatPackages.filter((pkg) => pkg.asn !== variousAsn);

    return packagesWithoutVarious.every(
      (pkg) =>
        pkg.receivedStatus === ManifestPackageStatus.qm ||
        pkg.receivedStatus === ManifestPackageStatus.received ||
        pkg.receivedStatus === ManifestPackageStatus.direct
    );
  }, [manifest]);

  async function onSubmitManifest() {
    try {
      setIsSubmitting(true);
      if (!manifest) {
        alert('Cannot load the manifest. Please reload the page.', 'error');
        return;
      }
      if (manifest.status === ManifestStatus.completed) {
        alert('Manifest has already been submitted.', 'warning');
        return;
      }
      alert('Submitted to Coupa', 'info');
      const receiptedItems = mapToReceiptedItems(manifest);
      for (const receiptedItem of receiptedItems) {
        const receiptResponse = await createSiteReceiptCoupa(receiptedItem);
        if (receiptResponse.error) {
          alert('Error receipting items to coupa, please try again.', 'error');
          console.error(receiptResponse.error);
          return;
        }
      }

      const uniqueAsns = [...new Set(receiptedItems.map((item) => item.asn))];

      const event: UpdateAsnProps = {
        asns: uniqueAsns.filter((asn): asn is string => asn !== undefined && asn !== null && asn !== variousAsn)
      };
      if (event.asns.length > 0) {
        await updateManifestAsnsCoupa(event);
      }

      manifest.status = ManifestStatus.completed;
      alert('Saving changes', 'info');
      await onSaveChanges();
      await handleSsccAsnStatusUpdate(tripData);
    } catch (error) {
      console.error('Error submitting manifest: ', error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleSsccAsnStatusUpdate(tripsheet: TripSheetProps | undefined) {
    if (!tripsheet) return;
    for (const leg of tripsheet.legs) {
      for (const manifest of leg.manifests) {
        for (const sscc of manifest.ssccs) {
          const foundSscc = await store.ssccs.get(sscc.ssccId);
          if (!foundSscc) {
            console.info(`SSCC with an ID ${sscc.ssccId} not found - ignoring asn update`);
            continue;
          }
          updateAsnStatus(foundSscc, 'Available');
          await store.ssccs.update(foundSscc);
        }
      }
    }
  }

  function mergeExistingLinesWithSubscription(incomingManifest: ManifestProps) {
    for (let incomingSscc of incomingManifest.ssccs) {
      updateLines(incomingSscc);
    }
  }

  function updateLines(incomingSscc: ManifestSSCCProps) {
    for (let currentSscc of manifest?.ssccs || []) {
      if (currentSscc.ssccId === incomingSscc.ssccId) {
        incomingSscc.packages.forEach((incomingPackage) => {
          const currentPackage = currentSscc.packages.find((p) => p.id === incomingPackage.id);
          if (currentPackage) {
            if (
              incomingPackage.receivedStatus === ManifestPackageStatus.direct ||
              incomingPackage.receivedStatus === ManifestPackageStatus.qm
            ) {
              incomingPackage.quantity = 0;
              return;
            }
            const isIncomingHigher = incomingPackage.quantity > currentPackage.quantity;
            incomingPackage.quantity = isIncomingHigher ? incomingPackage.quantity : currentPackage.quantity;
            incomingPackage.receivedStatus = isIncomingHigher
              ? incomingPackage.receivedStatus
              : currentPackage.receivedStatus;
          }
        });
      }
    }
  }

  async function onSaveChanges() {
    if (!manifest || !tripData) return;

    try {
      setIsSaving(true);

      let updatedManifest: ManifestProps = { ...manifest };
      if ((qmDictionary.length || directDictionary.length) > 0 && !isOnline) {
        alert('You are offline. Must be online to save Pending QM or Pending Direct', 'warning');
        return;
      }

      updatedManifest = await saveQMCoupa(updatedManifest);
      updatedManifest = await saveDirectReceipt(updatedManifest);

      setOriginalManifest(JSON.parse(JSON.stringify(updatedManifest)));

      const updatedLegs = tripData.legs.map((leg) => {
        const manifestIndex = leg.manifests.findIndex((m) => m.id === updatedManifest.id);
        if (manifestIndex !== -1) {
          leg.manifests[manifestIndex] = updatedManifest;
        }
        return leg;
      });

      const updatedTripSheet = {
        ...tripData,
        legs: updatedLegs
      };

      await store.tripSheets.update(updatedTripSheet);
      alert('Changes saved successfully', 'success');
      setDirectDictionary([]);
      setQMDictionary([]);
      setIsDirty(false);
    } catch (error) {
      console.error(error);
      alert('Failed to save changes', 'error');
    } finally {
      setIsSaving(false);
    }
  }

  async function saveQMCoupa(manifest: ManifestProps) {
    for (const qm of qmDictionary) {
      const qmResponse = await createQMCoupa(qm.value);
      if (qmResponse.error) {
        alert('Error creating QM in coupa, please try again.', 'error');
        console.error(qmResponse.error);
        return manifest;
      }
      const modifiedKey = qm.key.split('_')[1];
      const manifestPackages = manifest.ssccs
        ?.flatMap((sscc) => sscc.packages)
        .filter((pkg) => pkg.asn === modifiedKey);
      manifestPackages.forEach((pkg) => {
        pkg.receivedStatus = ManifestPackageStatus.qm;
      });
    }
    setQMDictionary([]);
    return manifest;
  }

  async function saveDirectReceipt(manifest: ManifestProps) {
    for (const directReceipt of directDictionary) {
      const asn = `MRL${crypto.randomUUID().substring(0, 8)}`;
      directReceipt.value.asn = asn;

      const coupaReceiptEvent = convertReceiptEventToCoupaReceiptEvent(
        directReceipt.value,
        user.name,
        userDetails?.location?.name,
        asn,
        true
      );

      const receiptResponse = await createReceiptCoupa(coupaReceiptEvent);
      if (receiptResponse.error) {
        alert('Error receipting items to coupa, please try again.', 'error');
        console.error(receiptResponse.error);
        return manifest;
      }
      const modifiedKey = directReceipt.key.split('_')[1];
      manifest.ssccs?.forEach((sscc) => {
        if (sscc.packages.some((pkg) => pkg.asn === modifiedKey)) {
          const packagesToUpdate = sscc.packages.filter((pkg) => pkg.asn === modifiedKey || pkg.asn === '1');
          packagesToUpdate.forEach((pkg) => {
            pkg.receivedStatus = ManifestPackageStatus.direct;
          });
        }
      });
    }
    setQMDictionary([]);
    return manifest;
  }

  function onQuantityChange(id: string, quantity: string) {
    if (!manifest) return;

    const updatedManifest = { ...manifest };

    const newPackage = updatedManifest.ssccs?.flatMap((sscc) => sscc.packages).find((pkg) => pkg.id === id);

    if (!newPackage) return;

    newPackage.quantity = parseInt(quantity) > newPackage.maxQuantity ? newPackage.maxQuantity : parseInt(quantity);
    newPackage.receivedStatus =
      newPackage.quantity === 0
        ? ManifestPackageStatus.notReceived
        : newPackage.quantity > 0 && newPackage.quantity < newPackage.maxQuantity
        ? ManifestPackageStatus.partial
        : ManifestPackageStatus.received;

    setManifest(updatedManifest);
    setIsDirty(checkIfManifestLinesChanged());
  }

  async function updateQMStatus(data: RaiseQMModel) {
    try {
      const statuses = (await store.workflowStatuses.getArray())?.filter((s) => s.name === 'Created');
      if (!statuses || !statuses[0].workflowStatusId) {
        alert('Error getting workflow status, please try again.', 'warning');
        return;
      }
      const createdId = statuses[0].workflowStatusId;
      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);

      if (!userPrinter) {
        alert('Error getting printer, please set your printer and try again.', 'warning');
        return;
      }

      const createQMPayload: CreateQMProps = {
        workflowStatusId: createdId,
        reasonId: data.reason.reasonId,
        issueId: data.issue.issueId,
        issue: data.issue.name,
        reason: data.reason.name,
        locationName: data.locationName,
        description: data.description,
        purchaseOrderNumber: data.purchaseOrderNumber,
        progress: data.progress,
        // attachments: data.attachments, //@Phase3: Add attachments
        printerIP: userPrinter?.printerIP,
        port: userPrinter?.printerPort,
        numberOfLabels: data.numberOfLabels,
        createdBy: user.name
      };
      const key = `qm_${selectedASN}`;
      const existingIndex = qmDictionary.findIndex((pkg) => pkg.key === key);

      if (existingIndex >= 0) {
        const updatedPackageData = [...qmDictionary];
        updatedPackageData[existingIndex] = { key, value: createQMPayload };
        setQMDictionary(updatedPackageData);
      } else {
        setQMDictionary([...qmDictionary, { key, value: createQMPayload }]);
      }

      const updatedManifest = { ...manifest };

      const newPackages = updatedManifest.ssccs
        ?.flatMap((sscc) => sscc.packages)
        .filter((pkg) => pkg.asn === selectedASN);

      if (!newPackages) {
        alert('Error updating manifest package, please try again.', 'warning');
        return;
      }

      newPackages.forEach((pkg) => (pkg.receivedStatus = ManifestPackageStatus.qm_pending));
      alert('QM added - please remember to save changes', 'info');
      updateSsccLine();
    } catch (error) {
      console.error("Error updating manifest package's QM status: ", error);
      alert('Error updating manifest package, please try again.', 'error');
    } finally {
      setOpenRaiseQM(false);
      setIsDirty(true);
    }
  }

  async function updateDirectStatus(pkg: ReceiptEventPackageProps) {
    const key = `direct_${selectedASN}`;
    const existingIndex = directDictionary.findIndex((pkg) => pkg.key === key);

    if (existingIndex >= 0) {
      const updatedPackageData = [...directDictionary];
      updatedPackageData[existingIndex] = { key, value: pkg };
      setDirectDictionary(updatedPackageData);
    } else {
      setDirectDictionary([...directDictionary, { key, value: pkg }]);
    }

    const updatedManifest = { ...manifest };

    const manifestPackages = updatedManifest.ssccs?.flatMap((sscc) => {
      if (sscc.packages.some((pkg) => pkg.asn === selectedASN)) {
        return sscc.packages.filter((pkg) => pkg.asn === selectedASN || pkg.asn === '1');
      }
      return [];
    });
    if (!manifestPackages) {
      alert('Error updating manifest package, please try again.', 'warning');
      setopenDirectModel(false);
      return;
    }
    manifestPackages.forEach((pkg) => (pkg.receivedStatus = ManifestPackageStatus.direct_pending));

    setopenDirectModel(false);
    setIsDirty(true);
    alert('Direct Receipt added - please remember to save changes', 'info');
    updateSsccLine();
  }

  function handleNavigateAway() {
    let isDirty = false;
    if (qmDictionary.length > 0 || directDictionary.length > 0) {
      isDirty = true;
    }
    isDirty = checkIfManifestLinesChanged();

    if (isDirty) {
      setOpenDirtyDialog(true);
    } else {
      navigate('/receive-manifest');
    }
  }

  function checkIfManifestLinesChanged() {
    if (originalManifest && manifest) {
      for (const sscc of originalManifest.ssccs) {
        for (const pkg of sscc.packages) {
          const correspondingPkg = manifest.ssccs.flatMap((sscc) => sscc.packages).find((p) => p.id === pkg.id);

          if (correspondingPkg && correspondingPkg.quantity !== pkg.quantity) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function renderManifestHeaderInfo() {
    return (
      <>
        <Stack>
          <Typography variant="body2" color={theme.palette.secondary.contrastText}>
            Site
          </Typography>
          <Typography color={theme.palette.primary.contrastText}>{legDestination}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" color={theme.palette.secondary.contrastText}>
            Manifest
          </Typography>
          <Typography color={theme.palette.primary.contrastText}>{manifest?.id}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" color={theme.palette.secondary.contrastText}>
            Status
          </Typography>
          <Typography color={theme.palette.primary.contrastText}>{manifest?.status}</Typography>
        </Stack>
      </>
    );
  }

  function shouldShowButtonsForTBF(pkg: ManifestPackageProps, sscc: ManifestSSCCProps) {
    return pkg.asn.startsWith(transportBookingFormPrefix) && pkg === sscc.packages[0];
  }

  function shouldShowButtonsForNonTBF(
    pkg: ManifestPackageProps,
    isFirstPackage: boolean,
    sameAsn: boolean,
    isPackageWithinTbf: boolean
  ) {
    return (
      isPackageWithinTbf &&
      !pkg.asn.startsWith(transportBookingFormPrefix) &&
      !isVarious(pkg) &&
      (!sameAsn || isFirstPackage)
    );
  }

  function isVarious(pkg: ManifestPackageProps) {
    return pkg.asn?.toUpperCase() === variousAsn;
  }

  const manifestIsOpen = manifest?.status === ManifestStatus.open;
  const legStatusLock = legStatus === ManifestLegStatus.inTransit;

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={handleNavigateAway}>
              <ArrowBackIcon fontSize="large" sx={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
            <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
              Receive Manifest
            </Typography>
          </Stack>
          <Stack direction="row" spacing={8}>
            <Box>
              <SearchField
                placeholder="Scan Item"
                startIcon={<SearchIcon />}
                onTrigger={(value) => setSearch(value)}
                sx={{ width: isMobile ? '20em' : '26em' }}
              />
            </Box>
            {!isMobile && renderManifestHeaderInfo()}
            <Stack direction="row" spacing={2} sx={{ flex: 1, justifyContent: 'end' }}>
              {manifestIsOpen && !legStatusLock && (
                <DarkButton variant="outlined" onClick={onSaveChanges} disabled={user.isReadOnly}>
                  Save Changes
                </DarkButton>
              )}
              <StyledButton
                variant="contained"
                onClick={onSubmitManifest}
                disabled={!canSubmit || !isOnline || !manifestIsOpen || user.isReadOnly}>
                Submit Manifest
              </StyledButton>
            </Stack>
          </Stack>
          <Stack direction="row" spacing="6em">
            {isMobile && renderManifestHeaderInfo()}
          </Stack>
        </Stack>
      </DarkUpperSection>
      <PageSection>
        <Stack spacing={2} sx={{ marginTop: '1em' }}>
          {isAnyPrinterDisabled && <DisabledPrintAlert />}
          <LoadingSpinner isLoading={isLoading}>
            {search.length && !manifest ? (
              <SearchNotFoundCard />
            ) : (
              <Table>
                <StyledTableHead>
                  <TableCell width="17%">SSCC #</TableCell>
                  <TableCell width="13%">PO</TableCell>
                  <TableCell width="15%">ASN</TableCell>
                  <TableCell width="15%">Package</TableCell>
                  <TableCell width="13%">Qty</TableCell>
                  <TableCell width="15%">Item Status</TableCell>
                  <TableCell width="12%"></TableCell>
                  <TableCell />
                </StyledTableHead>
                <TableBody>
                  {filteredSsccs?.map((sscc) => {
                    return sscc.packages.map((pkg, i) => {
                      const hasFtlAsn = pkg.asn.startsWith(ftlPrefix);
                      const isVarious = pkg.asn?.toUpperCase() === variousAsn;
                      const isFirstPackage = pkg === sscc.packages[0];
                      const isLastPackage = pkg === sscc.packages[sscc.packages.length - 1];
                      const sameAsn = pkg.asn === sscc.packages[i - 1]?.asn;
                      const hasDims = pkg.width && pkg.height && pkg.depth;
                      const isTransportBookingPackage = pkg.asn.startsWith(transportBookingFormPrefix);
                      const ssccContainsTbf = !sscc.packages.find((pkg) =>
                        pkg.asn.startsWith(transportBookingFormPrefix)
                      );
                      const packageStatusLock = [
                        ManifestPackageStatus.qm.toString(),
                        ManifestPackageStatus.direct.toString(),
                        ManifestPackageStatus.qm_pending.toString(),
                        ManifestPackageStatus.direct_pending.toString()
                      ].includes(pkg.receivedStatus);
                      return (
                        <StyledTableRow
                          key={pkg.id}
                          sx={
                            isLastPackage
                              ? {}
                              : {
                                  '& td': {
                                    padding: '1em 0',
                                    textAlign: 'start',
                                    borderBottomWidth: 0
                                  }
                                }
                          }>
                          <TableCell sx={{ fontWeight: 'bold' }}>{isFirstPackage ? sscc.ssccId : ''}</TableCell>
                          {<TableCell>{!sameAsn ? pkg.poNumber : ''}</TableCell>}

                          <TableCell>
                            <Typography>{!sameAsn ? pkg.asn : ''}</Typography>
                          </TableCell>
                          <TableCell>
                            <Stack>
                              <Typography>{pkg.packageType}</Typography>
                              {hasDims && (
                                <Typography variant="body2" color={theme.palette.text.secondary}>
                                  {pkg.height} x {pkg.width} x {pkg.depth}
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" spacing={2} alignItems="center">
                              {hasFtlAsn && !isTransportBookingPackage ? (
                                !isVarious && (
                                  <>
                                    <OutlinedInput
                                      sx={{ flex: 1 }}
                                      inputProps={{
                                        style: {
                                          padding: '0.5em'
                                        },
                                        min: 0,
                                        max: pkg.maxQuantity,
                                        step: 1
                                      }}
                                      type="number"
                                      value={pkg.quantity}
                                      onChange={(event) => {
                                        onQuantityChange(pkg.id, event.target.value);
                                        updateSsccLine();
                                      }}
                                      disabled={packageStatusLock || legStatusLock || !manifestIsOpen}
                                    />
                                    <Typography sx={{ flex: 1 }}>of {pkg.maxQuantity}</Typography>
                                  </>
                                )
                              ) : (
                                <>
                                  {(shouldShowButtonsForTBF(pkg, sscc) ||
                                    shouldShowButtonsForNonTBF(pkg, isFirstPackage, sameAsn, ssccContainsTbf)) && (
                                    <TableCell>
                                      {isOnline ? (
                                        <Tooltip
                                          title="ASN was not found. Please manually search for the ASN and select the items."
                                          enterTouchDelay={0}>
                                          <LightInfoButton
                                            variant="contained"
                                            onClick={() => {
                                              setSelectedASN(pkg.asn);
                                              setopenDirectModel(true);
                                            }}
                                            disabled={packageStatusLock || legStatusLock || !manifestIsOpen}
                                            sx={{ padding: '0.5em 1em' }}>
                                            Receipt
                                          </LightInfoButton>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          enterTouchDelay={0}
                                          title="You are offline. This feature is not available when offline.">
                                          <LightInfoButton variant="outlined">Receipt</LightInfoButton>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {shouldShowButtonsForTBF(pkg, sscc) ||
                            shouldShowButtonsForNonTBF(pkg, isFirstPackage, sameAsn, ssccContainsTbf) ||
                            ssccContainsTbf
                              ? pkg.receivedStatus ?? ''
                              : ''}
                          </TableCell>
                          {shouldShowButtonsForTBF(pkg, sscc) ||
                          shouldShowButtonsForNonTBF(pkg, isFirstPackage, sameAsn, ssccContainsTbf) ? (
                            <TableCell>
                              {isOnline ? (
                                <LightInfoButton
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedASN(pkg.asn);
                                    setOpenRaiseQM(true);
                                  }}
                                  disabled={
                                    packageStatusLock || isAnyPrinterDisabled || legStatusLock || !manifestIsOpen
                                  }
                                  sx={{ padding: '0.5em 1em' }}>
                                  Create QM
                                </LightInfoButton>
                              ) : (
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="You are offline. This feature is not available when offline.">
                                  <LightInfoButton variant="outlined">Create QM</LightInfoButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                        </StyledTableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            )}
          </LoadingSpinner>
        </Stack>
        <LoadingModal
          isLoading={isSaving || isSubmitting}
          titleText={isSaving ? 'Creating QM and Printing' : isSubmitting ? 'Submitting Manifest' : ''}
          bodyText={
            isSaving
              ? 'Please wait, creating a QM and printing labels.'
              : isSubmitting
              ? 'Submitting Manifest, please wait.'
              : ''
          }
        />
      </PageSection>
      {openRaiseQM && (
        <RaiseQMForm open={openRaiseQM} onClose={() => setOpenRaiseQM(false)} onSubmit={updateQMStatus} />
      )}
      {openDirectModel && (
        <DirectReceiptModel
          open={openDirectModel}
          onClose={() => setopenDirectModel(false)}
          onSubmit={updateDirectStatus}
          asn={selectedASN}
        />
      )}
      <ConfirmationDialog
        open={openDirtyDialog}
        onClose={() => setOpenDirtyDialog(false)}
        onConfirm={() => navigate('/receive-manifest')}
        title="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to leave the page?"
      />
    </>
  );
}
