import { Box, Dialog, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { CheckboxFormInput, NumberFieldFormInput, SelectFormInput, TextFieldFormInput } from '../formInputs';
import StyledButton from '../buttons/StyledButton';
import LightInfoButton from '../buttons/LightInfoButton';
import { usePackageTypes } from '../../hooks/usePackageType';
import DateFieldFormInput from '../formInputs/dateInput/DateFieldFormInput';
import { yearMonthDateFormat } from '../../common/dateTimes';
import dayjs from 'dayjs';
import { useIsOnline } from '../../hooks';

interface PrintShippingLabelsFormProps {
  open: boolean;
  onSubmit: (data: PrintShippingLabelsModel) => void;
  onClose: () => void;
  data?: PrintShippingLabelsModel;
  isLargeItem: boolean;
  isDirectReceipt: boolean;
  isTransportBooking: boolean;
  asn: string;
}

export interface PackageData {
  id: number;
  total: number;
  packageType: string;
  hasDangerousGoods: boolean;
  height?: number;
  width?: number;
  depth?: number;
  weight?: number;
}

export interface PrintShippingLabelsModel {
  dateReceived?: string;
  docketNumber?: string;
  packages: PackageData[];
}

export default function PrintShippingLabelsForm({
  open,
  onSubmit,
  onClose,
  data,
  isLargeItem,
  isDirectReceipt,
  isTransportBooking,
  asn
}: PrintShippingLabelsFormProps) {
  const theme = useTheme();
  const packageTypes = usePackageTypes();
  const { isOnline } = useIsOnline();
  const defaultPackage: PackageData = {
    id: Date.now(),
    total: 1,
    packageType: 'Carton',
    hasDangerousGoods: false
  };
  const defaultValues: PrintShippingLabelsModel = data?.packages.length
    ? data
    : {
        docketNumber: asn,
        packages: [defaultPackage]
      };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ defaultValues });
  const { fields, append, remove } = useFieldArray({ control, name: 'packages' });
  function onSubmitForm(data: PrintShippingLabelsModel) {
    onSubmit(data);
  }

  function handleAddPackage() {
    append(defaultPackage);
  }

  const minDate = dayjs().subtract(1, 'year').format(yearMonthDateFormat);
  const maxDate = dayjs().format(yearMonthDateFormat);
  const buttonText = isDirectReceipt
    ? 'Complete Receipt'
    : isLargeItem || isTransportBooking
    ? 'Move to Staging'
    : 'Next: Assign to SSCC';
  return (
    <Dialog open={open} maxWidth={isLargeItem || isTransportBooking ? 'md' : 'sm'} fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Print Shipping Labels
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Typography variant="body2">
            All configured labels for this transaction must be transported together.
          </Typography>
          <Stack direction="row" justifyContent="space-around" spacing={10}>
            <Box sx={{ width: '50%' }}>
              <TextFieldFormInput
                name="docketNumber"
                required
                label="Docket Number"
                control={control}
                error={errors['docketNumber']}
              />
            </Box>
            <Box sx={{ width: '35%' }}>
              <DateFieldFormInput
                name="dateReceived"
                required
                label="Date Received"
                control={control}
                error={errors?.dateReceived}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Box>
          </Stack>
          <Divider />

          {fields.map((field, index) => (
            <Stack spacing={1} key={field.id} direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="body2" gutterBottom>
                  Total
                </Typography>
                <NumberFieldFormInput
                  name={`packages[${index}].total`}
                  required
                  min={1}
                  control={control}
                  sx={{ minWidth: '0' }}
                  error={errors?.packages?.[index]?.total}
                />
              </Box>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Package Type
                </Typography>
                <SelectFormInput
                  name={`packages[${index}].packageType`}
                  control={control}
                  required
                  options={
                    packageTypes?.map((type) => ({ name: type, value: type })) || [{ name: 'Carton', value: 'Carton' }]
                  }
                  error={errors?.packages?.[index]?.packageType}
                />
              </Box>
              <Box
                sx={{
                  minWidth: '130px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography variant="body2" gutterBottom>
                  Dangerous Goods
                </Typography>
                <CheckboxFormInput
                  name={`packages[${index}].hasDangerousGoods`}
                  control={control}
                  error={errors?.packages?.[index]?.hasDangerousGoods}
                />
              </Box>

              {(isLargeItem || isTransportBooking) && (
                <>
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      Length
                    </Typography>
                    <NumberFieldFormInput
                      name={`packages[${index}].depth`}
                      required
                      min={1}
                      control={control}
                      sx={{ minWidth: '0' }}
                      error={errors?.packages?.[index]?.depth}
                      wholeNumbersOnly
                    />
                  </Box>
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      Width
                    </Typography>
                    <NumberFieldFormInput
                      name={`packages[${index}].width`}
                      required
                      min={1}
                      control={control}
                      sx={{ minWidth: '0' }}
                      error={errors?.packages?.[index]?.width}
                      wholeNumbersOnly
                    />
                  </Box>
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      Height
                    </Typography>
                    <NumberFieldFormInput
                      name={`packages[${index}].height`}
                      required
                      min={1}
                      control={control}
                      sx={{ minWidth: '0' }}
                      error={errors?.packages?.[index]?.height}
                      wholeNumbersOnly
                    />
                  </Box>
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      Weight
                    </Typography>
                    <NumberFieldFormInput
                      name={`packages[${index}].weight`}
                      required
                      min={1}
                      control={control}
                      sx={{ minWidth: '0' }}
                      error={errors?.packages?.[index]?.weight}
                    />
                  </Box>
                </>
              )}
              {fields.length > 1 && (
                <Box
                  sx={{
                    paddingLeft: '0.5em',
                    paddingTop: '1.5em'
                  }}>
                  <IconButton onClick={() => remove(index)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Stack>
          ))}

          <LightInfoButton sx={{ color: theme.palette.primary.main }} variant="contained" onClick={handleAddPackage}>
            Add another package type
          </LightInfoButton>
          <Divider />
          {isOnline ? (
            <StyledButton variant="contained" type="submit">
              {buttonText}
            </StyledButton>
          ) : (
            <Tooltip title="You are offline. This feature is not available when offline." enterTouchDelay={0}>
              <StyledButton variant="contained">{buttonText}</StyledButton>
            </Tooltip>
          )}
        </Stack>
      </form>
    </Dialog>
  );
}
