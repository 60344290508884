/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  Divider,
  IconButton,
  Link,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import StyledButton from '../buttons/StyledButton';
import ErrorIcon from '@mui/icons-material/Error';
import { useEffect, useState } from 'react';
import { useAlert, useCurrentUser, useIsOnline } from '../../hooks';
import StyledTableHead from '../tables/StyledTableHead';
import StyledTableRow from '../tables/StyledTableRow';
import { useTheme } from '@mui/material/styles';
import { buildPackage, receiptStatusRecord } from '../../common';
import { fetchPODetails } from '../../api/poApi';
import SearchField from '../textField/SearchField';
import LoadingModal from './LoadingModal';
import { ReceiptLine, ReceiptHeader } from '../../common/types';
import { ReceiptEventPackageProps } from '../../datastore/ssccs';
import { generateAlphanumericId } from '../../common/helperFunctions/generateId';
import {
  isReceiptStatus,
  isValidReceiptStatus,
  ReceiptPoStatus,
  ReceiptStatus
} from '../../common/status';
import { transportBookingFormPrefix } from '../../common/strings';

interface DirectReceiptModelProps {
  open: boolean;
  asn: string;
  onSubmit: (pkg: ReceiptEventPackageProps) => void;
  onClose: () => void;
}

export default function DirectReceiptModel({ open, onSubmit, onClose, asn }: DirectReceiptModelProps) {
  const theme = useTheme();
  const alert = useAlert();
  const user = useCurrentUser();
  const { isOnline } = useIsOnline();
  const [data, setData] = useState<ReceiptHeader>();
  const [isLoading, setIsLoading] = useState(true);
  const [noAsnMatch, setNoAsnMatch] = useState(false);
  const isTransportBookingPackage = asn.startsWith(transportBookingFormPrefix);

  useEffect(() => {
    fetchAndUpdateAsnDetails(asn);
  }, []);

  function areAllLineQuantitiesFullyReceived(): boolean {
    return data?.lines.every((line) => line.quantityReceived === line.quantityOrdered) ?? false;
  }

  function areAllLinesMarkedAsReceived(): boolean {
    const receivedStatuses = [
      ReceiptStatus.received,
      ReceiptStatus.soft_closed,
      ReceiptStatus.soft_closed_for_invoicing,
      ReceiptStatus.soft_closed_for_receiving
    ].map((a) => a.toLowerCase().replaceAll(' ', '_'));
    return data?.lines.every((line) => receivedStatuses.includes(line.status)) ?? false;
  }

  async function onSubmitForm() {
    if (!data?.lines.length) {
      alert('No lines to receipt', 'warning');
      setIsLoading(false);
      setNoAsnMatch(true);
      return;
    }
    const newPackage = buildPackage({
      packageType: '',
      po: data.poNumber,
      asn: '',
      jobId: data.jobId,
      shipToAttention: data.shipToAttention,
      assetNumber: data.assetNumber,
      supplierId: data.supplierId,
      supplierName: data.supplierName,
      companyName: data.company,
      priority: data.priority,
      status: ReceiptPoStatus.available,
      packageQuantity: null,
      hasDangerousGoods: false,
      qrCodeId: generateAlphanumericId(17),
      items: data?.lines.map((line) => ({
        orderLineId: line.id,
        partId: line.partId,
        sourcePartId: line.sourcePartId,
        quantityOrdered: line.quantityOrdered ?? null,
        quantityReceived: line.quantityReceived ?? null,
        description: line.description ?? '',
        purchaseType: line.purchaseType ?? '',
        receivingWarehouseId: line.receivingWarehouseId ?? null,
        receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
        inTransitWarehouseId: line.inTransitWarehouseId ?? null,
        inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
      }))
    });

    onSubmit(newPackage);
  }

  function getMappedStatus(status: string): string {
    if (isReceiptStatus(status)) {
      return receiptStatusRecord[status];
    } else {
      return 'Unknown';
    }
  }

  function onLineQuantityChange(lineId: number | null | undefined, quantity: string) {
    const clone = structuredClone(data);
    if (clone) {
      const line = clone.lines.find((line) => line.id === lineId);

      if (line && line.quantityOrdered) {
        line.quantityReceived =
          Number(quantity) > line.quantityOrdered ? Number(line.quantityOrdered) : Number(quantity);
        setData(clone);
      }
    }
  }

  function onAutoFill() {
    const clone = structuredClone(data);
    if (clone) {
      for (const line of clone.lines) {
        line.quantityReceived = line.quantityOrdered;
      }
      setData(clone);
    }
  }

  async function handleSearchAsn(searchTerm: string) {
    await fetchAndUpdateAsnDetails(searchTerm);
  }

  async function fetchAndUpdateAsnDetails(asn: string) {
    try {
      setIsLoading(true);

      if (!asn) {
        alert('Please enter an ASN', 'warning');
        setNoAsnMatch(true);
        return;
      }

      let response = await fetchPODetails('', asn);

      if (response.error) {
        alert('Issue retrieving PO, try again.', 'warning');
        setNoAsnMatch(true);
        return;
      }

      if (response.payload?.lines.length) {
        const updatedLines = response.payload.lines.map((line: ReceiptLine) => {
          const adjustedQuantityOrdered = Math.max((line.quantityOrdered ?? 0) - (line.quantityReceived ?? 0), 0);

          return {
            ...line,
            quantityOrdered: adjustedQuantityOrdered,
            quantityReceived: 0,
            partId: line.partId ?? 0,
            sourcePartId: line.sourcePartId ?? ''
          };
        });

        response.payload.lines = updatedLines;
        setData(response.payload);
        setNoAsnMatch(false);
      } else {
        setNoAsnMatch(true);
      }
    } catch (error) {
      console.error('Error retrieving PO: ', error);
      setNoAsnMatch(true);
      alert('Error retrieving PO, try searching.', 'warning');
    } finally {
      setIsLoading(false);
    }
  }

  const Header = ({ includeText = false }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        {includeText ? (
          <Typography variant="h4" fontWeight="bold">
            Direct Receipt
          </Typography>
        ) : (
          <span style={{ flexGrow: 1 }} />
        )}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} maxWidth={!isOnline || noAsnMatch ? 'md' : isLoading ? 'xs' : 'lg'} fullWidth>
      <Stack spacing={3} sx={{ padding: '1em 2em' }}>
        {isLoading ? (
          <LoadingModal isLoading={isLoading} titleText="" bodyText="Searching ASN" />
        ) : noAsnMatch ? (
          <>
            <Header />
            <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ padding: '1em' }}>
              <ErrorIcon color="error" />
              <Typography fontWeight="bold">Sorry, your search did not return any results</Typography>
              <Typography>You can either enter an ASN to search or create a QM.</Typography>
            </Stack>
            <Stack alignItems="center" sx={{ width: '100%' }}>
              <SearchField
                placeholder="Search for ASN"
                startIcon={<SearchIcon />}
                onTrigger={(searchTerm) => handleSearchAsn(searchTerm)}
                sx={{ width: '16em' }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack spacing={1}>
              <Header includeText={true} />
              <Typography variant="body2">
                When receiving items directly from a supplier, ensure that it has receipted correctly.
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  Site
                </Typography>
                <Typography fontWeight="bold">{data?.site}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  Supplier
                </Typography>
                <Typography>{data?.supplierName}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  Company
                </Typography>
                <Typography>{data?.company}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  Ship to Attn.
                </Typography>
                <Typography>{data?.shipToAttention}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  PO
                </Typography>
                <Typography>
                  {data?.poNumber ? (
                    <Stack sx={{ fontWeight: 'bold' }}>
                      <Typography fontWeight="bold">{data.poNumber}</Typography>
                      <Link
                        component="a"
                        href={`${process.env.REACT_APP_COUPA_BASE_URL}${data.poNumber}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        View
                      </Link>
                    </Stack>
                  ) : (
                    ''
                  )}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  PO Status
                </Typography>
                <Typography>{getMappedStatus(data?.poStatus ?? '')}</Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2}>
              <Table>
                <StyledTableHead>
                  <TableCell width="5%">#</TableCell>
                  <TableCell width="10%">Status</TableCell>
                  <TableCell width="17%">Part ID</TableCell>
                  <TableCell width="10%" title="Purchase Type">
                    Type
                  </TableCell>
                  <TableCell width="43%">Description</TableCell>
                  <TableCell width="15%">Quantity</TableCell>
                </StyledTableHead>
                <TableBody>
                  {data?.lines.map((line, index) => {
                    const isDisabled = !isValidReceiptStatus(line.status);
                    const fontColour = isDisabled ? theme.palette.text.secondary : theme.palette.text.primary;
                    return (
                      <StyledTableRow key={line.id}>
                        <TableCell sx={{ color: fontColour, fontWeight: isDisabled ? 'normal' : 'bold' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: fontColour }}>{getMappedStatus(line.status ?? '')}</TableCell>
                        <TableCell sx={{ color: fontColour }}>{line.sourcePartId}</TableCell>
                        <TableCell sx={{ color: fontColour }}>{line.purchaseType}</TableCell>
                        <TableCell sx={{ color: fontColour }}>{line.description}</TableCell>
                        <TableCell sx={{ color: fontColour }}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <OutlinedInput
                              sx={{ flex: 1 }}
                              inputProps={{
                                style: {
                                  padding: '0.5em'
                                },
                                min: 0,
                                max: line.quantityOrdered,
                                step: 1
                              }}
                              type="number"
                              value={line.quantityReceived}
                              onChange={(event) => onLineQuantityChange(line.id, event.target.value)}
                              disabled={isDisabled}
                            />
                            <Typography sx={{ flex: 1 }}>of {line.quantityOrdered}</Typography>
                          </Stack>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Stack sx={{ paddingTop: '0.5em' }}>
                <StyledButton
                  variant="contained"
                  onClick={onAutoFill}
                  sx={{ padding: '0.5em 1em' }}
                  disabled={data?.lines.some((line) => !isValidReceiptStatus(line.status))}>
                  Autofill
                </StyledButton>
              </Stack>
            </Stack>
            {isTransportBookingPackage && !areAllLineQuantitiesFullyReceived() && !areAllLinesMarkedAsReceived() && (
              <Typography color="error" variant="body2">
                All lines must be fully received for transport booking packages.
              </Typography>
            )}
            <StyledButton
              variant="contained"
              onClick={() => onSubmitForm()}
              disabled={
                user.isReadOnly ||
                (isTransportBookingPackage && !areAllLineQuantitiesFullyReceived() && !areAllLinesMarkedAsReceived())
              }>
              Receipt
            </StyledButton>
          </>
        )}
      </Stack>
    </Dialog>
  );
}
